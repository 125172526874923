import { render, staticRenderFns } from "./UserNavBar.vue?vue&type=template&id=f842e5cc&scoped=true&"
import script from "./UserNavBar.vue?vue&type=script&lang=js&"
export * from "./UserNavBar.vue?vue&type=script&lang=js&"
import style0 from "./UserNavBar.vue?vue&type=style&index=0&id=f842e5cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f842e5cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VCard,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VNavigationDrawer})
